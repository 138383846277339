import React from 'react';

import { SOUND_URL, THE_BRIDE, SOUND_BY, URL_WA_INVETTO, URL_IG_INVETTO, URL_INVETTO } from '@/constants';

const REFERER = {
  target: '_blank',
  rel: 'noreferrer',
};

function CopyRight() {
  return (
    <footer>
      <div className="container copy-right">
        <div className="row">
          <div className="col-md-12 text-center">
            <p style={{ fontSize: '12px' }}>
              &copy; {new Date().getFullYear()} {THE_BRIDE}. All Rights Reserved. <br />
              Audio by{' '}
              <a href={SOUND_URL} {...REFERER} className="pr-co" dangerouslySetInnerHTML={{ __html: SOUND_BY }} />
              <br />
              Icon by{' '}
              <a href="https://flaticon.com" {...REFERER}>
                Flat Icon
              </a>
              <br />
              <br /> <br />
              Create with Love by{' '}
              <a href={URL_INVETTO} {...REFERER} className="pr-co">
                Invetto.id
              </a>
              <br />
              <a href={URL_IG_INVETTO} {...REFERER}>
                Instagram
              </a>
              <a href={URL_WA_INVETTO} {...REFERER} style={{ marginLeft: '8px' }}>
                WhatsApp
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default CopyRight;
