import React from 'react';
import { bool } from 'prop-types';

import WithAnimation from '../WithAnimation/UseIntersect';
import CopyRight from './CopyRight';
import { styWrapper } from './styles';

import WishesSection from '@components/WishesSection';

// import GiftCard from '../GiftCard';
// import EmbedLive from './EmbedLive';

function FooterSection({ isInvitation }) {
  return (
    <>
      {/* <EmbedLive /> */}
      {/* <GiftCard /> */}
      <WishesSection />
      
      <div css={styWrapper(true)}>
        <div style={{ padding: '0 16px' }}>
          <div className="row padding__content">
            <div className="col-md-8 col-md-offset-2 text-center">
              <WithAnimation>
                <p>
                  Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i <br/>
                  berkenan hadir untuk memberikan doa restu kepada kami.<br/>
                  Atas kehadiran dan doa restunya.
                </p>
              </WithAnimation>
              <WithAnimation delay={100}>
                <h2 className="main-font pr-co text__title" style={{ fontSize: '4rem' }}>
                  Terima Kasih
                </h2>
              </WithAnimation>
            </div>
          </div>
        </div>
        <WithAnimation>
          <CopyRight />
        </WithAnimation>
      </div>
    </>
  );
}

FooterSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(FooterSection);
